import React from "react"
// import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

const Post = props => {
  return (
    <Layout>
      <Seo />
      <div>
        <h1>Post</h1>
      </div>
    </Layout>
  )
}

export default Post
